import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="home-header">
      <div className="home-header-inner">
        <h1>Software craftsmanship</h1>
      </div>
    </div>

    <div className="home-content">
      <div className="home-content-inner">
        <div id="about">
          <h2>About</h2>
          <p>Artxcode is a London based software house, specialising in crafting beautiful web and mobile applications. We place emphasis on design, accessibility and performance.</p>
          <p>We build our own products, as well as working with our clients to build stellar software for their users.</p>
        </div>

        <div id="clients">
          <h2>Clients</h2>

          <div className="logos-container">
          <div className="logo-style">
              <Logo name="gds" />
              <p style={{marginTop: "16px"}}>We helped GDS better understand the usage of public sector domains in order to help the owners better manage them, as well as discover abusive domains.</p>
              <Link to="/clients/gds" className="case-study-link">View case study</Link>
            </div>
            <div className="logo-style">
              <Logo name="workfinder" />
              <p style={{marginTop: "16px"}}>We helped Workfinder launch a new work experience platform in time for summer internships, making it easy for employers to offer students valuable short term remote work placements.</p>
              <Link to="/clients/workfinder" className="case-study-link">View case study</Link>
            </div>
            <div className="logo-style">
              <Logo name="donation" />
              <p>We helped Do Nation launch a global initiative, with mutliple partners, to mobalise 1 billion people to help fight climate change, which received extensive media coverage.</p>
              <Link to="/clients/donation" className="case-study-link">View case study</Link>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

const Logo = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      workfinderImage: file(relativePath: { eq: "workfinder-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      doNationImage: file(relativePath: { eq: "donation-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      gdsImage: file(relativePath: { eq: "gds-logo.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  if (!data?.workfinderImage?.childImageSharp?.fluid && !data?.doNationImage?.childImageSharp?.fluid && !data?.gdsImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  if (name === "workfinder") {
    return <Img
      fluid={data.workfinderImage.childImageSharp.fluid}
      imgStyle={{
        objectFit: 'contain',
      }}
    />
  }
  else if (name === "donation") {
    return <Img
      fluid={data.doNationImage.childImageSharp.fluid}
      imgStyle={{
        objectFit: 'contain',
        marginLeft: '-12px',
      }}
    />
  }
  else if (name === "gds") {
    return <Img
      fluid={data.gdsImage.childImageSharp.fluid}
      imgStyle={{
        objectFit: 'contain',
      }}
    />
  }

  return null
}

export default IndexPage
